<template>
  <div class="oneTicketVeto">
    <div class="flex-box">
      <div>
        <el-button :type="vetoVisible?'':'success'"
          @click="loadOneTicketVetoList(1)">教师一票否决
        </el-button>
        <el-button :type="vetoVisible?'success':''"
          @click="loadOneTicketVetoList(2)">学生一票否决
        </el-button>
      </div>
      <div>
        <span style="margin-right:20px">年度</span>
        <el-date-picker v-model="queryYear"
          value-format="yyyy"
          type="year"
          @change="queryYearOneTicketVeto"
          placeholder="请选择年度">
        </el-date-picker>
      </div>
    </div>
    <el-table :data="studentList">
      <el-table-column prop="name"
        align="center"
        label="标题"></el-table-column>
      <el-table-column prop="evaluation_name"
        align="center"
        label="姓名"></el-table-column>
      <el-table-column prop="mobile"
        align="center"
        label="联系方式"></el-table-column>
      <el-table-column prop="assessed_name"
        align="center"
        label="被评价教师"></el-table-column>
      <el-table-column prop="cname"
        align="center"
        label="班级"></el-table-column>
      <el-table-column prop="opertime"
        align="center"
        label="时间"></el-table-column>
      <el-table-column prop="score"
        align="center"
        label="分值"></el-table-column>
      <el-table-column align="center"
        label="操作"
        width="200px">
        <template slot-scope="scope">
          <el-button type="primary"
            @click="viewQuestion(scope.row)">查看
          </el-button>
          <el-button type="warning"
            @click="resetQuestion(scope.row)">重置问卷
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync='viewQuestionVisible'
      width="600px"
      title="查看问卷">
      <div class="viewQuestion-box">
        <div v-for="option in question"
          :style="oneTicketVetoOption(option)"
          :key="option.id">
          <div class="padding-box">
            {{option.sort_index}}、{{option.title}}
            {{option.type === 1 ? '(一票否决项)' : ''}}
          </div>
          <el-radio-group v-model="option.option_id"
            class="padding-group">
            <el-radio disabled
              v-for="optic in option.optionList"
              :key="optic.id"
              :label="optic.id">{{optic.content}}
            </el-radio>
          </el-radio-group>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "oneTicketVeto",
  data() {
    return {
      studentList: [],
      viewQuestionVisible: false,
      question: [],
      queryYear: "",
      vetoVisible: false,
      type: -1,
    };
  },
  created() {
    this.defaultQueryYear();
    this.loadOneTicketVetoList(1);
  },
  methods: {
    defaultQueryYear() {
      let date = new Date();
      this.queryYear = String(date.getFullYear());
    },
    queryYearOneTicketVeto() {
      if (this.vetoVisible) {
        this.type = 2;
      } else {
        this.type = 1;
      }
      this.loadOneTicketVetoList(this.type);
    },
    loadOneTicketVetoList(type) {
      const that = this;
      this.type = type;
      let data = { nd: this.queryYear, type };
      if (this.queryYearId) {
        data.nd = this.queryYearId;
      }
      if (type === 1) {
        //教师
        this.vetoVisible = false;
      } else if (type === 2) {
        //学生
        this.vetoVisible = true;
      }
      this.$post("/base/queryNotPass.do", data)
        .then((res) => {
          that.studentList = res.data;
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    viewQuestion(currentData) {
      const that = this;
      let data = { ndid: currentData.ndid, qtid: currentData.id };
      let apiUrl = "/base/PQueryQuestionDetail.do";
      if (this.vetoVisible) {
        data = {
          ndid: currentData.ndid,
          evaluation_mid: currentData.evaluation_mid,
          stuNo: currentData.stuno,
          assessed_mid: currentData.assessed_mid,
          classid: currentData.classid,
        };
        apiUrl = "/parent/queryStuQuestionDetailByStu.do";
      }
      this.$post(apiUrl, data)
        .then((res) => {
          that.question = res.data;
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
      this.viewQuestionVisible = true;
    },
    oneTicketVetoOption(option) {
      if (option.type === 1) {
        return "color: rgb(211, 90, 90)";
      }
    },
    resetQuestion(currentStudent) {
      const that = this;
      this.$confirm("是否重置该问卷", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/base/deleteNotPass.do", {
            ndid: currentStudent.ndid,
            evaluation_mid: currentStudent.evaluation_mid,
            assessed_mid: currentStudent.assessed_mid,
            type: this.type,
          })
            .then((res) => {
              that.$message({
                type: "success",
                message: "重置成功!",
              });
              that.loadOneTicketVetoList(this.type);
            })
            .catch((error) => {
              that.$message({
                type: "warning",
                message: error.message,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消重置",
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.input-box {
  width: 217px;
  margin-right: 15px;
}

.padding-box {
  padding: 10px;
}

.viewQuestion-box {
  height: 500px;
  overflow-x: auto;
  padding: 5px;
}

.flex-box {
  display: flex;
}
/deep/ .el-radio__input.is-disabled.is-checked + span.el-radio__label {
  color: #00a1de;
}

/deep/ .el-radio__input.is-disabled.is-checked .el-radio__inner {
  background-color: #00a1de;
}
</style>
